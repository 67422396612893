import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />


    <Container className="pt-5">
      <h1>404 NOT FOUND</h1>
      <img src="https://media4.giphy.com/media/hEc4k5pN17GZq/giphy.gif" className="img-fluid w-100" />
    </Container>
  </Layout>
)

export default NotFoundPage
